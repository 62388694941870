(function(angular, $){
  'use strict';

  angular.module('organization', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.organization', {
            url: '/organizacion/:organizationId',
            abstract: true,
            templateUrl: 'app/modules/organization/main.tpl.html',
            resolve: {
              organizacion: ['$q', '$stateParams', 'organizaciones',
                function($q, $stateParams, organizaciones){
                  var deferred = $q.defer();

                  var organizacion;
                  if(_.contains(['39', '40'], $stateParams.organizationId)){
                    if($stateParams.organizationId === '39'){
                      organizacion = {
                        id: 39,
                        categoria: 'MAVDT',
                        abreviatura: 'Min. Ambiente',
                        nombre: 'Ministerio de Ambiente y Desarrollo Sostenible',
                        codigo: 1,
                        url: null
                      };
                      deferred.resolve(organizacion);
                    }else if($stateParams.organizationId === '40'){
                      organizacion = {
                        id: 40,
                        categoria: 'UAESPNN',
                        abreviatura: 'PNN',
                        nombre: 'Parques Nacionales Naturales de Colombia',
                        codigo: 2,
                        url: null
                      };
                      deferred.resolve(organizacion);
                    }else{
                      deferred.reject();
                    }
                  }else{
                    organizacion = _.find(organizaciones, {id: parseInt($stateParams.organizationId, 10)});
                    if(organizacion){
                      deferred.resolve(organizacion);
                    }else{
                      deferred.reject();
                    }
                  }

                  return deferred.promise;
                }
              ],
              traslapes: ['$q', '$stateParams', 'Traslapes',
                function($q, $stateParams, Traslapes){
                  var deferred = $q.defer();
                  var params = {
                    categoria: 'ORGANIZACION',
                    // @TODO: que va aqui?
                    //clase: 'DEPARTAMENTO',
                    termino_id: $stateParams.organizationId
                  };
                  Traslapes.areas(params).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ],
              organizacionAreas: ['$q', '$stateParams', 'Organizaciones',
                function($q, $stateParams, Organizaciones){
                  let deferred = $q.defer();
                  let params = {
                    organizacionId: $stateParams.organizationId,
                    tipo: 'propia',
                  };
                  Organizaciones.areas(params).then(function(data){
                    deferred.resolve(data);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ],
              organizacionTraslapes: ['$q', '$stateParams', 'Traslapes',
                function($q, $stateParams, Traslapes){
                  var deferred = $q.defer();
                  Traslapes.byOrganization($stateParams.organizationId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ],
              // @TODO: borrar
              /*documents: ['$q', '$stateParams', 'Documentos',
                function($q, $stateParams, Documentos){
                  var deferred = $q.defer();
                  Documentos.byOrganization($stateParams.organizationId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ]*/
            },
            controller: 'Organization.MainController'
          })
          .state('main.views.organization.views', {
            url: '',
            views: {
              intro: {
                templateUrl: 'app/modules/organization/intro.tpl.html',
                controller: 'Organization.IntroController'
              },
              // @TODO: borrar
              /*timeline: {
                templateUrl: 'app/modules/organization/timeline.tpl.html',
                controller: 'Organization.TimelineController'
              },*/
              chart: {
                templateUrl: 'app/modules/organization/chart.tpl.html',
                controller: 'Organization.ChartController'
              },
              documents: {
                templateUrl: 'app/modules/organization/areas.tpl.html',
                controller: 'Organization.AreasController'
              },
              timeSeries: {
                templateUrl: 'app/modules/organization/timeSeries.tpl.html',
                controller: 'Organization.TimeSeriesController'
              }
            }
          });
      }
    ])

    .controller('Organization.MainController', ['$scope',
      function($scope){
        // Valores iniciales
        $scope.common = {model: {}};
      }
    ])

    .controller('Organization.IntroController', ['$scope', '$timeout', 'ServerRequest', 'MathService', 'Imagenes', 'Organizaciones', 'backend', 'organizacion', 'traslapes', 'organizacionAreas',
      function($scope, $timeout, ServerRequest, MathService, Imagenes, Organizaciones, backend, organizacion, traslapes, organizacionAreas){
        debug.info('Organization.IntroController traslapes', traslapes, 'organizacion', organizacion);

        $scope.organizacion = organizacion;

        if (organizacion.id == 39) {
          $scope.numberDelegacion = traslapes.filter((item) => {
            if (organizacion.id == 40) {
              return false;
            }
            const clase = (item.clase === 'MADS') ? 'MinAmbiente' : item.clase;
            return (clase != item.termino && clase != 'CAR');
          }).length;
        } else if (organizacion.id == 40) {
          $scope.numberDelegacion = traslapes.filter((item) => {
            if (organizacion.id != 40) {
              return false;
            }
            const clase = (item.clase === 'PNN') ? 'PNNC' : item.clase;
            return (clase != item.termino);
          }).length;
        } else {
          let traslapesAreas = traslapes.filter((item) => {
            return (item.clase === 'CAR');
          });
          $scope.numberAreas = traslapesAreas.length;

          $scope.numberDeclaradas = (organizacionAreas && organizacionAreas.length) ? organizacionAreas[0].conteo : null;

          $scope.numberDelegacion = traslapes.filter((item) => {
            if (organizacion.id == 40) {
              return false;
            }
            const clase = (item.clase === 'MADS') ? 'MinAmbiente' : item.clase;
            return (clase != item.termino && clase != 'CAR');
          }).length;
        }

        $scope.noNumberDelegacion = traslapes.filter((item) => {
          if(organizacion.id == 40){
            const clase = (item.clase === 'PNN') ? 'PNNC' : item.clase;
            return (clase == item.termino);
          }else{
            //TODO tiene el problema con la sigla CAR pero por ahora nos funciona
            const clase = (item.clase === 'MADS') ? 'MinAmbiente' : item.clase;
            return (clase == item.termino && clase != 'CAR' && item.id == 40);
          }
        }).length;

        if(organizacion.id == 40){
          Organizaciones.conteoAreasDeclaradasRnsc()
            .then(function(conteo){
              $scope.numberAreasDeclaradasRnsc = conteo;
            });
        }

        Organizaciones.conteoAreasDeclaradas(organizacion.id)
          .then(function(conteo){
            $scope.numberAreasDeclaradas = conteo;
          });

        $scope.areasChartsSR = new ServerRequest();
        $scope.imagesSR = new ServerRequest();
        $scope.mapFlag = false;

        // Calcula los valores de la grafica de geografica
        var geografica = {terrestre: 0, maritima: 0};
        var geograficaDelegacion = {terrestre: 0, maritima: 0};
        var geograficaNoDelegacion = {terrestre: 0, maritima: 0};
        angular.forEach(traslapes, function(item){
          geografica.terrestre += item.area.geografica.terrestre;
          geografica.maritima += item.area.geografica.maritima;
          if(organizacion.id == 40) {
            const clase = (item.clase === 'PNN') ? 'PNNC' : item.clase;
            if (clase != item.termino) {
              geograficaDelegacion.terrestre += item.area.geografica.terrestre;
              geograficaDelegacion.maritima += item.area.geografica.maritima;
            }
            if (clase == item.termino) {
              geograficaNoDelegacion.terrestre += item.area.geografica.terrestre;
              geograficaNoDelegacion.maritima += item.area.geografica.maritima;
            }
          }else{
            //TODO tiene el problema con la sigla CAR pero por ahora nos funciona
            const clase = (item.clase === 'MADS') ? 'MinAmbiente' : item.clase;
            if (clase != item.termino && clase != 'CAR') {
              geograficaDelegacion.terrestre += item.area.geografica.terrestre;
              geograficaDelegacion.maritima += item.area.geografica.maritima;
            }
            if (clase == item.termino && clase != 'CAR') {
              geograficaNoDelegacion.terrestre += item.area.geografica.terrestre;
              geograficaNoDelegacion.maritima += item.area.geografica.maritima;
            }
          }
        });
        geografica.total = geografica.terrestre + geografica.maritima;
        geograficaDelegacion.total = geograficaDelegacion.terrestre + geograficaDelegacion.maritima;
        geograficaNoDelegacion.total = geograficaNoDelegacion.terrestre + geograficaNoDelegacion.maritima;
        geografica.porcentajes = {
          terrestre: MathService.round((geografica.terrestre / geografica.total) * 100),
          maritima: MathService.round((geografica.maritima / geografica.total) * 100)
        };
        geograficaDelegacion.porcentajes = {
          terrestre: MathService.round((geograficaDelegacion.terrestre / geograficaDelegacion.total) * 100),
          maritima: MathService.round((geograficaDelegacion.maritima / geograficaDelegacion.total) * 100)
        };
        geograficaNoDelegacion.porcentajes = {
          terrestre: MathService.round((geograficaNoDelegacion.terrestre / geograficaNoDelegacion.total) * 100),
          maritima: MathService.round((geograficaNoDelegacion.maritima / geograficaNoDelegacion.total) * 100)
        };
        $scope.geografica = geografica;
        $scope.geograficaDelegacion = geograficaDelegacion;
        $scope.geograficaNoDelegacion = geograficaNoDelegacion;

        // Calcula los valores de la grafica de resolucion
        var acto = {terrestre: 0, maritima: 0};
        var actoDelegacion = {terrestre: 0, maritima: 0};
        var actoNoDelegacion = {terrestre: 0, maritima: 0};
        angular.forEach(traslapes, function(item){
          acto.terrestre += item.area.acto.terrestre;
          acto.maritima += item.area.acto.maritima;
          if(organizacion.id == 40) {
            const clase = (item.clase === 'PNN') ? 'PNNC' : item.clase;
            if (clase != item.termino) {
              actoDelegacion.terrestre += item.area.acto.terrestre;
              actoDelegacion.maritima += item.area.acto.maritima;
            }
            if (clase == item.termino) {
              actoNoDelegacion.terrestre += item.area.acto.terrestre;
              actoNoDelegacion.maritima += item.area.acto.maritima;
            }
          }else {
            //TODO tiene el problema con la sigla CAR pero por ahora nos funciona
            const clase = (item.clase === 'MADS') ? 'MinAmbiente' : item.clase;
            if (clase != item.termino && clase != 'CAR') {
              actoDelegacion.terrestre += item.area.acto.terrestre;
              actoDelegacion.maritima += item.area.acto.maritima;
            }
            if (clase == item.termino && clase != 'CAR') {
              actoNoDelegacion.terrestre += item.area.acto.terrestre;
              actoNoDelegacion.maritima += item.area.acto.maritima;
            }
          }
        });
        acto.total = acto.terrestre + acto.maritima;
        actoDelegacion.total = actoDelegacion.terrestre + actoDelegacion.maritima;
        actoNoDelegacion.total = actoNoDelegacion.terrestre + actoNoDelegacion.maritima;
        acto.porcentajes = {
          terrestre: MathService.round((acto.terrestre / acto.total) * 100),
          maritima: MathService.round((acto.maritima / acto.total) * 100)
        };
        actoDelegacion.porcentajes = {
          terrestre: MathService.round((actoDelegacion.terrestre / actoDelegacion.total) * 100),
          maritima: MathService.round((actoDelegacion.maritima / actoDelegacion.total) * 100)
        };
        actoDelegacion.porcentajes = {
          terrestre: MathService.round((actoNoDelegacion.terrestre / actoNoDelegacion.total) * 100),
          maritima: MathService.round((actoNoDelegacion.maritima / actoNoDelegacion.total) * 100)
        };
        $scope.acto = acto;
        $scope.actoDelegacion = actoDelegacion;
        $scope.actoNoDelegacion = actoNoDelegacion;
        // Graficas de areas
        $scope.areasChartsSR.start();
        $timeout(function(){
          $scope.areasChartsSR.success();
          $scope.chart1 = {
            id: 'chart1',
            values: [geografica.terrestre, geografica.maritima],
            colors: ['#fe9927', '#cd2d2e']
          };
          $scope.chart2 = {
            id: 'chart2',
            values: [acto.terrestre, acto.maritima],
            colors: ['#6ca754', '#72a9db']
          };
          if($scope.numberDelegacion){
            $scope.delegacionChart1 = {
              id: 'delegacionChart1',
              values: [geograficaDelegacion.terrestre, geograficaDelegacion.maritima],
              colors: ['#fe9927', '#cd2d2e']
            };
            // @TODO: esta se duplico a partir de $scope.delegacionChart1. Se debe ajustar
            $scope.delegacionChart98 = {
              id: 'delegacionChart98',
              values: [geograficaDelegacion.terrestre, geograficaDelegacion.maritima],
              colors: ['#fe9927', '#cd2d2e']
            };
            $scope.delegacionChart2 = {
              id: 'delegacionChart2',
              values: [actoDelegacion.terrestre, actoDelegacion.maritima],
              colors: ['#6ca754', '#72a9db']
            };
            // @TODO: esta se duplico a partir de $scope.delegacionChart2. Se debe ajustar
            $scope.delegacionChart99 = {
              id: 'delegacionChart99',
              values: [actoDelegacion.terrestre, actoDelegacion.maritima],
              colors: ['#6ca754', '#72a9db']
            };
          }
          if($scope.noNumberDelegacion){
            $scope.delegacionNoChart1 = {
              id: 'delegacionNoChart1',
              values: [geograficaNoDelegacion.terrestre, geograficaNoDelegacion.maritima],
              colors: ['#fe9927', '#cd2d2e']
            };
            $scope.delegacionNoChart2 = {
              id: 'delegacionNoChart2',
              values: [actoNoDelegacion.terrestre, actoNoDelegacion.maritima],
              colors: ['#6ca754', '#72a9db']
            };
          }
        });

        $timeout(function(){
          var params = [
            '',
            'pnn:runap2,pnn:departamentos'
          ];
          if(organizacion.id == 39){
            // Ministerio de Ambiente
            params.push("organizacion = 'MADS';INCLUDE");
          }else if(organizacion.id == 40){
            // PNN
            params.push("organizacion = 'PNN';INCLUDE");
          }else{
            params.push("INTERSECTS(shape, querySingle('pnn:cars', 'shape', 'car = ''" + organizacion.abreviatura + "'''));INCLUDE");
          }
          debug.info('visor params', params[0], params[1], params[2]);
          V.init(params[0], params[1], params[2]);
        }, 100);

        // Opciones de swiper
        $scope.swiperOptions = {
          autoHeight: true,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          spaceBetween: 30,
          loop: true,
          autoplay: 5000,
          // Disable preloading of all images
          preloadImages: false,
          // Enable lazy loading
          lazyLoading: true
        };

        // Consulta las imagenes del carousel
        $scope.imagesSR.start();
        Imagenes.byOrganization(organizacion.id).then(function(data){
          $scope.imagesSR.success();
          $scope.images = data.items;
        }, function(response){
          $scope.imagesSR.setErrorResponse(response);
        });
      }
    ])

    // @TODO: borrar
    /*.controller('Organization.TimelineController', ['$scope', '$filter', '$timeout', 'documents',
      function($scope, $filter, $timeout, documents){
        // No puede haber mas de un elemento con la misma fecha para que muestre
        // bien la linea de tiempo
        var uniqueDocuments = _.uniq(documents, function(document){
          return document.fecha;
        });
        var timelineItems = _.map(uniqueDocuments, function(document){
          var date = new Date(document.fecha * 1000);
          date.setDate(date.getDate() + 1);
          var dateStr = $filter('date')(date, 'dd/MM/yyyy');
          return {
            date: dateStr,
            label: dateStr
          };
        });
        angular.forEach(timelineItems, function(item){
          item.documents = _.filter(documents, function(document){
            var date = new Date(document.fecha * 1000);
            date.setDate(date.getDate() + 1);
            var dateStr = $filter('date')(date, 'dd/MM/yyyy');
            return (dateStr === item.date);
          });
        });
        $scope.timelineItems = timelineItems;

        if(timelineItems.length){
          $timeout(function(){
            var timelines = $('.cd-horizontal-timeline');
            (timelines.length > 0) && initTimeline(timelines, 80, 240);
          });
        }
      }
    ])*/

    .controller('Organization.ChartController', ['$scope', '$timeout', 'MathService', 'StringService', 'ServerRequest', /*'Charts',*/ 'traslapes', 'organizacionTraslapes',
      function($scope, $timeout, MathService, StringService, ServerRequest, /*Charts,*/ traslapes, organizacionTraslapes){
        $scope.chartSR = new ServerRequest();

        debug.info('traslapes', traslapes);
        debug.info('organizacionTraslapes', organizacionTraslapes);

        // Obtiene los tipos de graficas
        var categories = _.map(organizacionTraslapes, function(item){
          var label;
          if(item.categoria === 'SISTEMA_NATURAL'){
            if(item.clase === 'BIOGEOGRAFICO'){
              label = 'Biogeográficos';
            }else if(item.clase === 'BIOMA'){
              label = 'Biomas';
            }else if(item.clase === 'ECOSISTEMA'){
              label = 'Ecosistemas';
            }
          }else if(item.categoria === 'CLASIFICACION'){
            if(item.clase === 'UICN'){
              label = 'Categorías UICN';
            }
          }else if(item.categoria === 'DIVISION_POLITICA'){
            if(item.clase === 'DEPARTAMENTO'){
              label = 'Departamentos';
            }else if(item.clase === 'MUNICIPIO'){
              label = 'Municipios';
            }
          }else if(item.categoria === 'OBJETIVO'){
            if(item.clase === 'GENERAL'){
              label = 'Objetivos generales';
            }else if(item.clase === 'ESPECIFICO'){
              label = 'Objetivos específicos';
            }
          }else if(item.categoria === 'ORGANIZACION'){
            if(item.clase === 'CAR'){
              label = 'CARs';
            }else{
              label = 'Organizaciones';
            }
          }else if(item.categoria === 'TRASLAPE'){
            if(item.clase === 'AREA'){
              label = 'Áreas protegidas';
            }
          }else if(item.categoria === 'USO'){
            label = 'Usos';
          }
          if(label){
            return {
              value: _.sprintf('%s.%s', item.categoria, item.clase),
              label: label
            };
          }
          return null;
        });
        categories = _.filter(categories, function(item){
          return !!item;
        });
        categories = _.uniq(categories, function(item){
          return item.value;
        });
        categories.push({
          value: 'TRASLAPE.AREA',
          label: 'Áreas protegidas'
        });
        categories = _.sortBy(categories, function(item){
          return StringService.normalize(item.label);
        });
        if(categories.length){
          // Selecciona el boton inicial
          $scope.common.model.chart = categories[0].value;
        }
        $scope.charts = categories;

        var generateChart = function(chart){
          var chartOptions = {
            title: {
              text: chart.label
            },
            subtitle: {
              text: '(hectáreas)'
            },
            colorAxis: {
              minColor: '#FFFFFF',
              maxColor: '#7CB5EC'
            },
            tooltip: {
              formatter: function(){
                var key;
                if(this.key.length > 30){
                  key = _.truncate(this.key, 30);
                }else{
                  key = this.key;
                }
                return _.sprintf('<strong>%s:</strong> %s ha', key, this.point.value);
              }
            },
            series: [{
              type: 'treemap',
              layoutAlgorithm: 'squarified',
              data: []
            }]
          };

          debug.info('generateChart', chart);
          var terminos;
          if(chart.value === 'TRASLAPE.AREA'){
            terminos = traslapes;
            angular.forEach(terminos, function(termino){
              var value = 0;
              angular.forEach(termino.area.geografica, function(item){
                if(item){
                  value += parseFloat(item);
                }
              });
              var color = MathService.round(value);
              value = parseFloat(MathService.toPrecision(value, 4));
              chartOptions.series[0].data.push({
                name: termino.contexto_termino,
                value: value,
                colorValue: color
              });
            });
          }else{
            terminos = _.filter(organizacionTraslapes, function(termino){
              return (_.sprintf('%s.%s', termino.categoria, termino.clase) === chart.value);
            });
            angular.forEach(terminos, function(termino){
              var value = 0;
              angular.forEach(termino.area.geografica, function(item){
                if(item){
                  value += parseFloat(item);
                }
              });
              var color = MathService.round(value);
              value = parseFloat(MathService.toPrecision(value, 4));
              chartOptions.series[0].data.push({
                name: termino.termino,
                value: value,
                colorValue: color
              });
            });
          }
          debug.info('terminos', terminos, 'serie', chartOptions.series[0].data);

          $scope.chartOptions = chartOptions;
        };

        $scope.changeChart = function(chart){
          // Consulta las opciones del chart
          /*$scope.chartSR.start();
          Charts.get(chart).then(function(data){
            $scope.chartSR.success();
            $scope.chartOptions = data;
          }, function(response){
            $scope.chartSR.setErrorResponse(response);
          });*/

          $scope.chartSR.start();
          $timeout(function(){
            var chartItem = _.find($scope.charts, {value: chart});
            generateChart(chartItem);
            $scope.chartSR.success();
          });
        };

        if($scope.charts.length){
          $scope.changeChart($scope.charts[0].value);
        }
      }
    ])

    .controller('Organization.AreasController', ['$scope', 'MathService', 'StringService', 'organizacion', 'traslapes',
      function($scope, MathService, StringService, organizacion, traslapes){
        $scope.organizacion = organizacion;
        $scope.numberAreas = traslapes.length;

        traslapes = _.sortBy(angular.copy(traslapes), function(item){
          return StringService.normalize(item.contexto_termino);
        });
        $scope.traslapes = _.map(traslapes, function(item){
          var total = item.area.geografica.terrestre + item.area.geografica.maritima;
          item.area.geografica.total = parseFloat(MathService.toPrecision(total, 4));
          return item;
        });
      }
    ])

    .controller('Organization.TimeSeriesController', ['$scope', 'ServerRequest', 'MathService', 'Areas', 'organizacion',
      function($scope, ServerRequest, MathService, Areas, organizacion){
        $scope.yearsSR = new ServerRequest();

        $scope.yearsSR.start();
        Areas.areasActoByYear(organizacion.id).then(function(data){
          $scope.yearsSR.success();

          var categories = _.map(data, 'anio');
          var seaSeries = _.map(data, 'km_maritimos');
          seaSeries = _.map(seaSeries, function(item){
            return MathService.round(item * 100);
          });
          var landSeries = _.map(data, 'km_terrestres');
          landSeries = _.map(landSeries, function(item){
            return MathService.round(item * 100);
          });
          var acumSeries = _.map(data, function(item){
            if(item.km_maritimos === null){
              item.km_maritimos = 0;
            }
            if(item.km_terrestres === null){
              item.km_terrestres = 0;
            }
            return (parseFloat(item.km_maritimos) + parseFloat(item.km_terrestres)) * 100;
          });
          var i;
          for(i = 1; i < acumSeries.length; i++){
            acumSeries[i] = MathService.round(acumSeries[i - 1] + acumSeries[i]);
          }

          $scope.yearsChartOptions = {
            chart: {
              zoomType: 'xy'
            },
            title: {
              text: 'Serie de tiempo'
            },
            subtitle: {
              text: '(Calculado por acto administrativo)'
            },
            plotOptions: {
              column: {
                stacking: 'normal'
              },
              spline: {
                lineWidth: 4,
                states: {
                  hover: {
                    lineWidth: 5
                  }
                },
                marker: {
                  enabled: false
                }
              }
            },
            xAxis: [{
              categories: categories,
              crosshair: true,
              labels: {
                autoRotation: [-40]
              }
            }],
            yAxis: [
              {  // Primary yAxis
                title: {
                  text: 'Marítimo y terrestre (ha)',
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                labels: {
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                min: 0
              },
              {  // Secondary yAxis
                title: {
                  text: 'Acumulado (ha)',
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                labels: {
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                min: 0,
                opposite: true
              }
            ],
            tooltip: {
              shared: true
            },
            series: [
              {
                name: 'Marítimo',
                type: 'column',
                data: seaSeries,
                tooltip: {
                  valueSuffix: ' ha'
                },
                color: Highcharts.getOptions().colors[0]
              },
              {
                name: 'Terrestre',
                type: 'column',
                data: landSeries,
                tooltip: {
                  valueSuffix: ' ha'
                },
                color: '#e26700'
              },
              {
                name: 'Acumulado',
                type: 'spline',
                yAxis: 1,
                data: acumSeries,
                tooltip: {
                  valueSuffix: ' ha'
                },
                color: Highcharts.getOptions().colors[7]
                /*marker: {
                  enabled: false
                }*/
              }
            ]
          };
        }, function(response){
          $scope.yearsSR.setErrorResponse(response);
        });
      }
    ]);
}(window.angular, window.$));
